// import React, { useState } from "react";
// import './BOIRForm.css';  // Ensure you have your CSS file for styling errors
// import { useNavigate } from "react-router-dom";

// const usStates = [ "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", 
//   "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", 
//   "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", 
//   "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
//   "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", 
//   "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", 
//   "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", 
//   "West Virginia", "Wisconsin", "Wyoming" ];

// const BOIRForm = () => {
//   const [companyDetails, setCompanyDetails] = useState({
//     companyName: "",
//     companyEIN: "",
//     companyCity: "",
//     companyState: "",
//     companyZip: "",
//     companyAddress: "",
//     companyEmail: "",
//     companyPhone: "",
//     companyStateOfRegistration: "",
//     numberOfOwners: 1,
//   });

//   const [owners, setOwners] = useState([]);
//   const [formErrors, setFormErrors] = useState({});
//   const navigate = useNavigate();

//   const handleCompanyChange = (e) => {
//     setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
//   };

//   const handleOwnerChange = (index, e) => {
//     const updatedOwners = [...owners];
//     updatedOwners[index] = { ...updatedOwners[index], [e.target.name]: e.target.value };
//     setOwners(updatedOwners);
//   };

//   const handleFileUpload = (index, e) => {
//     const file = e.target.files[0];
//     const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];

//     if (file && allowedTypes.includes(file.type)) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const updatedOwners = [...owners];
//         updatedOwners[index] = { ...updatedOwners[index], idFile: reader.result };
//         setOwners(updatedOwners);
//       };
//       reader.readAsDataURL(file);
//     } else {
//       console.error("Invalid file type");
//     }
//   };

//   const validateForm = () => {
//     let errors = {};
//     const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
//     const today = new Date();

//     // Validation for company details
//     if (!companyDetails.companyName) errors.companyName = "Company name is required.";
//     if (!companyDetails.companyEIN) errors.companyEIN = "Company EIN is required.";
//     if (!companyDetails.companyCity) errors.companyCity = "Company city is required.";
//     if (!companyDetails.companyState) errors.companyState = "Company state is required.";
//     if (!companyDetails.companyZip) {
//       errors.companyZip = "Company ZIP code is required.";
//     } else if (!/^\d{5}$/.test(companyDetails.companyZip)) {
//       errors.companyZip = "ZIP code must be exactly 5 digits.";
//     }
//     if (!companyDetails.companyAddress) errors.companyAddress = "Company address is required.";
//     if (!companyDetails.companyStateOfRegistration) errors.companyStateOfRegistration = "Company state of registration is required.";
//     if (!companyDetails.companyEmail) {
//       errors.companyEmail = "Company email is required.";
//     } else if (!emailPattern.test(companyDetails.companyEmail)) {
//       errors.companyEmail = "Company email is invalid.";
//     }
//     if (!companyDetails.companyPhone) errors.companyPhone = "Company phone number is required.";

//     // Validation for owners
//     owners.forEach((owner, index) => {
//       if (!owner.firstName) errors[`firstName${index}`] = `First name for Owner ${index + 1} is required.`;
//       if (!owner.lastName) errors[`lastName${index}`] = `Last name for Owner ${index + 1} is required.`;
//       if (!owner.dob) {
//         errors[`dob${index}`] = `Date of Birth for Owner ${index + 1} is required.`;
//       } else if (new Date(owner.dob) > today) {
//         errors[`dob${index}`] = `Date of Birth for Owner ${index + 1} cannot be in the future.`;
//       }
//       if (!owner.currentAddress) errors[`currentAddress${index}`] = `Current address for Owner ${index + 1} is required.`;
//       if (!owner.city) errors[`city${index}`] = `City for Owner ${index + 1} is required.`;
//       if (!owner.state) errors[`state${index}`] = `State for Owner ${index + 1} is required.`;
//       if (!owner.zip) {
//         errors[`zip${index}`] = `ZIP code for Owner ${index + 1} is required.`;
//       } else if (!/^\d{5}$/.test(owner.zip)) {
//         errors[`zip${index}`] = `ZIP code must be exactly 5 digits.`;
//       }
//       if (!owner.idFile) errors[`idFile${index}`] = `ID File for Owner ${index + 1} is required.`;
//     });

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!validateForm()) {
//       return;
//     }

//     localStorage.setItem('companyDetails', JSON.stringify(companyDetails));
//     localStorage.setItem('owners', JSON.stringify(owners));

//     navigate("/payment");
//   };

//   const renderOwnerForms = () => {
//   const ownerForms = [];
//   for (let i = 0; i < companyDetails.numberOfOwners; i++) {
//     ownerForms.push(
//       <div key={i}>
//         <h3>Owner {i + 1}</h3>
//         <div>
//           <label>First Name</label>
//           <input 
//             type="text" 
//             name="firstName" 
//             onChange={(e) => handleOwnerChange(i, e)} 
//             className={formErrors[`firstName${i}`] ? 'error-border' : ''}
//           />
//           {formErrors[`firstName${i}`] && <p className="error">{formErrors[`firstName${i}`]}</p>}
//         </div>
//         <div>
//           <label>Middle Name</label>
//           <input type="text" name="middleName" onChange={(e) => handleOwnerChange(i, e)} />
//         </div>
//         <div>
//           <label>Last Name</label>
//           <input 
//             type="text" 
//             name="lastName" 
//             onChange={(e) => handleOwnerChange(i, e)} 
//             className={formErrors[`lastName${i}`] ? 'error-border' : ''}
//           />
//           {formErrors[`lastName${i}`] && <p className="error">{formErrors[`lastName${i}`]}</p>}
//         </div>
//         <div>
//           <label>Date of Birth</label>
//           <input
//             type="date"
//             name="dob"
//             max={new Date().toISOString().split("T")[0]} // Set max date to today
//             onChange={(e) => handleOwnerChange(i, e)}
//             className={formErrors[`dob${i}`] ? 'error-border' : ''}
//           />
//           {formErrors[`dob${i}`] && <p className="error">{formErrors[`dob${i}`]}</p>}
//         </div>
//         <div>
//           <label>Current Address</label>
//           <input 
//             type="text" 
//             name="currentAddress" 
//             onChange={(e) => handleOwnerChange(i, e)} 
//             className={formErrors[`currentAddress${i}`] ? 'error-border' : ''}
//           />
//           {formErrors[`currentAddress${i}`] && <p className="error">{formErrors[`currentAddress${i}`]}</p>}
//         </div>
//         <div>
//           <label>City</label>
//           <input 
//             type="text" 
//             name="city" 
//             onChange={(e) => handleOwnerChange(i, e)} 
//             className={formErrors[`city${i}`] ? 'error-border' : ''}
//           />
//           {formErrors[`city${i}`] && <p className="error">{formErrors[`city${i}`]}</p>}
//         </div>
//         <div>
//           <label>State</label>
//           <select 
//             name="state" 
//             onChange={(e) => handleOwnerChange(i, e)} 
//             className={formErrors[`state${i}`] ? 'error-border' : ''}
//           >
//             <option value="">Select State</option>
//             {usStates.map((state) => (
//               <option key={state} value={state}>
//                 {state}
//               </option>
//             ))}
//           </select>
//           {formErrors[`state${i}`] && <p className="error">{formErrors[`state${i}`]}</p>}
//         </div>
//         <div>
//           <label>ZIP Code</label>
//           <input 
//             type="text" 
//             name="zip" 
//             onChange={(e) => handleOwnerChange(i, e)} 
//             className={formErrors[`zip${i}`] ? 'error-border' : ''}
//           />
//           {formErrors[`zip${i}`] && <p className="error">{formErrors[`zip${i}`]}</p>}
//         </div>
//         <div>
//           <label>Upload Identification (PDF, PNG, JPG, JPEG)</label>
//           <input type="file" name="idFile" onChange={(e) => handleFileUpload(i, e)} />
//           {formErrors[`idFile${i}`] && <p className="error">{formErrors[`idFile${i}`]}</p>}
//         </div>
//       </div>
//     );
//   }
//   return ownerForms;
// };

//   return (
//     <form onSubmit={handleSubmit}>
//       <h2>Company Information</h2>
//       <div>
//         <label>What is your company name?</label>
//         <input 
//           type="text" 
//           name="companyName" 
//           value={companyDetails.companyName} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyName ? 'error-border' : ''}
//         />
//         {formErrors.companyName && <p className="error">{formErrors.companyName}</p>}
//       </div>
//       <div>
//         <label>What is your company's EIN?</label>
//         <input 
//           type="text" 
//           name="companyEIN" 
//           value={companyDetails.companyEIN} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyEIN ? 'error-border' : ''}
//         />
//         {formErrors.companyEIN && <p className="error">{formErrors.companyEIN}</p>}
//       </div>
//       <div>
//         <label>What is your company's address?</label>
//         <input 
//           type="text" 
//           name="companyAddress" 
//           value={companyDetails.companyAddress} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyAddress ? 'error-border' : ''}
//         />
//         {formErrors.companyAddress && <p className="error">{formErrors.companyAddress}</p>}
//       </div>
//       <div>
//         <label>City</label>
//         <input 
//           type="text" 
//           name="companyCity" 
//           value={companyDetails.companyCity} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyCity ? 'error-border' : ''}
//         />
//         {formErrors.companyCity && <p className="error">{formErrors.companyCity}</p>}
//       </div>
//       <div>
//         <label>State</label>
//         <select 
//           name="companyState" 
//           value={companyDetails.companyState} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyState ? 'error-border' : ''}
//         >
//           <option value="">Select State</option>
//           {usStates.map((state) => (
//             <option key={state} value={state}>
//               {state}
//             </option>
//           ))}
//         </select>
//         {formErrors.companyState && <p className="error">{formErrors.companyState}</p>}
//       </div>
//       <div>
//         <label>ZIP Code</label>
//         <input 
//           type="text" 
//           name="companyZip" 
//           value={companyDetails.companyZip} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyZip ? 'error-border' : ''}
//         />
//         {formErrors.companyZip && <p className="error">{formErrors.companyZip}</p>}
//       </div>
//       <div>
//         <label>What is your company's email?</label>
//         <input 
//           type="email" 
//           name="companyEmail" 
//           value={companyDetails.companyEmail} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyEmail ? 'error-border' : ''}
//         />
//         {formErrors.companyEmail && <p className="error">{formErrors.companyEmail}</p>}
//       </div>
//       <div>
//         <label>What is your company's phone number?</label>
//         <input 
//           type="tel" 
//           name="companyPhone" 
//           value={companyDetails.companyPhone} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyPhone ? 'error-border' : ''}
//         />
//         {formErrors.companyPhone && <p className="error">{formErrors.companyPhone}</p>}
//       </div>
//       <div>
//         <label>What is your company's state of registration?</label>
//         <select 
//           name="companyStateOfRegistration" 
//           value={companyDetails.companyStateOfRegistration} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyStateOfRegistration ? 'error-border' : ''}
//         >
//           <option value="">Select State</option>
//           {usStates.map((state) => (
//             <option key={state} value={state}>
//               {state}
//             </option>
//           ))}
//         </select>
//         {formErrors.companyStateOfRegistration && <p className="error">{formErrors.companyStateOfRegistration}</p>}
//       </div>
//       <div>
//         <label>How many owners does this business have?</label>
//         <input 
//           type="number" 
//           name="numberOfOwners" 
//           value={companyDetails.numberOfOwners} 
//           onChange={handleCompanyChange} 
//         />
//       </div>

//       <h2>Owner Information</h2>
//       {renderOwnerForms()}

//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// export default BOIRForm;

// import React, { useState } from "react";
// import './BOIRForm.css';  // Ensure you have your CSS file for styling errors
// import { useNavigate } from "react-router-dom";

// const usStates = [ "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", 
//   "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", 
//   "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", 
//   "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
//   "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", 
//   "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", 
//   "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", 
//   "West Virginia", "Wisconsin", "Wyoming" ];

// const BOIRForm = () => {
//   const [companyDetails, setCompanyDetails] = useState({
//     companyName: "",
//     companyEIN: "",
//     companyCity: "",
//     companyState: "",
//     companyZip: "",
//     companyAddress: "",
//     companyEmail: "",
//     companyPhone: "",
//     companyStateOfRegistration: "",
//     numberOfOwners: 1,
//   });

//   const [owners, setOwners] = useState([]);
//   const [formErrors, setFormErrors] = useState({});
//   const navigate = useNavigate();

//   const handleCompanyChange = (e) => {
//     setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
//   };

//   const handleOwnerChange = (index, e) => {
//     const updatedOwners = [...owners];
//     updatedOwners[index] = { ...updatedOwners[index], [e.target.name]: e.target.value };
//     setOwners(updatedOwners);
//   };

//   const handleFileUpload = (index, e) => {
//     const file = e.target.files[0];
//     const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];

//     if (file && allowedTypes.includes(file.type)) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const updatedOwners = [...owners];
//         updatedOwners[index] = { ...updatedOwners[index], idFile: reader.result };
//         setOwners(updatedOwners);
//       };
//       reader.readAsDataURL(file);
//     } else {
//       console.error("Invalid file type");
//     }
//   };

//   const validateForm = () => {
//     let errors = {};
//     const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
//     const today = new Date();

//     // Validation for company details
//     if (!companyDetails.companyName) errors.companyName = "Company name is required.";
//     // EIN is now optional, so no validation for it
//     if (!companyDetails.companyCity) errors.companyCity = "Company city is required.";
//     if (!companyDetails.companyState) errors.companyState = "Company state is required.";
//     if (!companyDetails.companyZip) {
//       errors.companyZip = "Company ZIP code is required.";
//     } else if (!/^\d{5}$/.test(companyDetails.companyZip)) {
//       errors.companyZip = "ZIP code must be exactly 5 digits.";
//     }
//     if (!companyDetails.companyAddress) errors.companyAddress = "Company address is required.";
//     if (!companyDetails.companyStateOfRegistration) errors.companyStateOfRegistration = "Company state of registration is required.";
//     if (!companyDetails.companyEmail) {
//       errors.companyEmail = "Company email is required.";
//     } else if (!emailPattern.test(companyDetails.companyEmail)) {
//       errors.companyEmail = "Company email is invalid.";
//     }
//     if (!companyDetails.companyPhone) errors.companyPhone = "Company phone number is required.";

//     // Validation for owners
//     owners.forEach((owner, index) => {
//       if (!owner.firstName) errors[`firstName${index}`] = `First name for Owner ${index + 1} is required.`;
//       if (!owner.lastName) errors[`lastName${index}`] = `Last name for Owner ${index + 1} is required.`;
//       if (!owner.dob) {
//         errors[`dob${index}`] = `Date of Birth for Owner ${index + 1} is required.`;
//       } else if (new Date(owner.dob) > today) {
//         errors[`dob${index}`] = `Date of Birth for Owner ${index + 1} cannot be in the future.`;
//       }
//       if (!owner.currentAddress) errors[`currentAddress${index}`] = `Current address for Owner ${index + 1} is required.`;
//       if (!owner.city) errors[`city${index}`] = `City for Owner ${index + 1} is required.`;
//       if (!owner.state) errors[`state${index}`] = `State for Owner ${index + 1} is required.`;
//       if (!owner.zip) {
//         errors[`zip${index}`] = `ZIP code for Owner ${index + 1} is required.`;
//       } else if (!/^\d{5}$/.test(owner.zip)) {
//         errors[`zip${index}`] = `ZIP code must be exactly 5 digits.`;
//       }
//       if (!owner.idFile) errors[`idFile${index}`] = `ID File for Owner ${index + 1} is required.`;
//     });

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!validateForm()) {
//       return;
//     }

//     localStorage.setItem('companyDetails', JSON.stringify(companyDetails));
//     localStorage.setItem('owners', JSON.stringify(owners));

//     navigate("/payment");
//   };

//   const renderOwnerForms = () => {
//     const ownerForms = [];
//     for (let i = 0; i < companyDetails.numberOfOwners; i++) {
//       ownerForms.push(
//         <div key={i}>
//           <h3>Owner {i + 1}</h3>
//           <div>
//             <label>First Name</label>
//             <input 
//               type="text" 
//               name="firstName" 
//               onChange={(e) => handleOwnerChange(i, e)} 
//               className={formErrors[`firstName${i}`] ? 'error-border' : ''}
//             />
//             {formErrors[`firstName${i}`] && <p className="error">{formErrors[`firstName${i}`]}</p>}
//           </div>
//           <div>
//             <label>Middle Name</label>
//             <input type="text" name="middleName" onChange={(e) => handleOwnerChange(i, e)} />
//           </div>
//           <div>
//             <label>Last Name</label>
//             <input 
//               type="text" 
//               name="lastName" 
//               onChange={(e) => handleOwnerChange(i, e)} 
//               className={formErrors[`lastName${i}`] ? 'error-border' : ''}
//             />
//             {formErrors[`lastName${i}`] && <p className="error">{formErrors[`lastName${i}`]}</p>}
//           </div>
//           <div>
//             <label>Date of Birth</label>
//             <input
//               type="date"
//               name="dob"
//               max={new Date().toISOString().split("T")[0]} // Set max date to today
//               onChange={(e) => handleOwnerChange(i, e)}
//               className={formErrors[`dob${i}`] ? 'error-border' : ''}
//             />
//             {formErrors[`dob${i}`] && <p className="error">{formErrors[`dob${i}`]}</p>}
//           </div>
//           <div>
//             <label>Current Address</label>
//             <input 
//               type="text" 
//               name="currentAddress" 
//               onChange={(e) => handleOwnerChange(i, e)} 
//               className={formErrors[`currentAddress${i}`] ? 'error-border' : ''}
//             />
//             {formErrors[`currentAddress${i}`] && <p className="error">{formErrors[`currentAddress${i}`]}</p>}
//           </div>
//           <div>
//             <label>City</label>
//             <input 
//               type="text" 
//               name="city" 
//               onChange={(e) => handleOwnerChange(i, e)} 
//               className={formErrors[`city${i}`] ? 'error-border' : ''}
//             />
//             {formErrors[`city${i}`] && <p className="error">{formErrors[`city${i}`]}</p>}
//           </div>
//           <div>
//             <label>State</label>
//             <select 
//               name="state" 
//               onChange={(e) => handleOwnerChange(i, e)} 
//               className={formErrors[`state${i}`] ? 'error-border' : ''}
//             >
//               <option value="">Select State</option>
//               {usStates.map((state) => (
//                 <option key={state} value={state}>
//                   {state}
//                 </option>
//               ))}
//             </select>
//             {formErrors[`state${i}`] && <p className="error">{formErrors[`state${i}`]}</p>}
//           </div>
//           <div>
//             <label>ZIP Code</label>
//             <input 
//               type="text" 
//               name="zip" 
//               onChange={(e) => handleOwnerChange(i, e)} 
//               className={formErrors[`zip${i}`] ? 'error-border' : ''}
//             />
//             {formErrors[`zip${i}`] && <p className="error">{formErrors[`zip${i}`]}</p>}
//           </div>
//           <div>
//             <label>Upload Identification (Driver License/Passport)</label>
//             <input type="file" name="idFile" onChange={(e) => handleFileUpload(i, e)} />
//             {formErrors[`idFile${i}`] && <p className="error">{formErrors[`idFile${i}`]}</p>}
//           </div>
//         </div>
//       );
//     }
//     return ownerForms;
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <h2>Company Information</h2>
//       <div>
//         <label>What is your company name?</label>
//         <input 
//           type="text" 
//           name="companyName" 
//           value={companyDetails.companyName} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyName ? 'error-border' : ''}
//         />
//         {formErrors.companyName && <p className="error">{formErrors.companyName}</p>}
//       </div>
//       <div>
//         <label>What is your company's EIN?</label>
//         <input 
//           type="text" 
//           name="companyEIN" 
//           value={companyDetails.companyEIN} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyEIN ? 'error-border' : ''}
//         />
//         {/* EIN is now optional, so no error message */}
//       </div>
//       <div>
//         <label>What is your company's address?</label>
//         <input 
//           type="text" 
//           name="companyAddress" 
//           value={companyDetails.companyAddress} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyAddress ? 'error-border' : ''}
//         />
//         {formErrors.companyAddress && <p className="error">{formErrors.companyAddress}</p>}
//       </div>
//       <div>
//         <label>City</label>
//         <input 
//           type="text" 
//           name="companyCity" 
//           value={companyDetails.companyCity} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyCity ? 'error-border' : ''}
//         />
//         {formErrors.companyCity && <p className="error">{formErrors.companyCity}</p>}
//       </div>
//       <div>
//         <label>State</label>
//         <select 
//           name="companyState" 
//           value={companyDetails.companyState} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyState ? 'error-border' : ''}
//         >
//           <option value="">Select State</option>
//           {usStates.map((state) => (
//             <option key={state} value={state}>
//               {state}
//             </option>
//           ))}
//         </select>
//         {formErrors.companyState && <p className="error">{formErrors.companyState}</p>}
//       </div>
//       <div>
//         <label>ZIP Code</label>
//         <input 
//           type="text" 
//           name="companyZip" 
//           value={companyDetails.companyZip} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyZip ? 'error-border' : ''}
//         />
//         {formErrors.companyZip && <p className="error">{formErrors.companyZip}</p>}
//       </div>
//       <div>
//         <label>What is your company's email?</label>
//         <input 
//           type="email" 
//           name="companyEmail" 
//           value={companyDetails.companyEmail} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyEmail ? 'error-border' : ''}
//         />
//         {formErrors.companyEmail && <p className="error">{formErrors.companyEmail}</p>}
//       </div>
//       <div>
//         <label>What is your company's phone number?</label>
//         <input 
//           type="tel" 
//           name="companyPhone" 
//           value={companyDetails.companyPhone} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyPhone ? 'error-border' : ''}
//         />
//         {formErrors.companyPhone && <p className="error">{formErrors.companyPhone}</p>}
//       </div>
//       <div>
//         <label>What is your company's state of registration?</label>
//         <select 
//           name="companyStateOfRegistration" 
//           value={companyDetails.companyStateOfRegistration} 
//           onChange={handleCompanyChange} 
//           className={formErrors.companyStateOfRegistration ? 'error-border' : ''}
//         >
//           <option value="">Select State</option>
//           {usStates.map((state) => (
//             <option key={state} value={state}>
//               {state}
//             </option>
//           ))}
//         </select>
//         {formErrors.companyStateOfRegistration && <p className="error">{formErrors.companyStateOfRegistration}</p>}
//       </div>
//       <div>
//         <label>How many owners does this business have?</label>
//         <input 
//           type="number" 
//           name="numberOfOwners" 
//           value={companyDetails.numberOfOwners} 
//           onChange={handleCompanyChange} 
//         />
//       </div>

//       <h2>Owner Information</h2>
//       {renderOwnerForms()}

//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// export default BOIRForm;
import React, { useState, useEffect } from "react";
import './BOIRForm.css';  // Ensure you have your CSS file for styling errors
import { useNavigate } from "react-router-dom";

const usStates = [ "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", 
  "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", 
  "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", 
  "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
  "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", 
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", 
  "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", 
  "West Virginia", "Wisconsin", "Wyoming" ];

const BOIRForm = () => {
  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    companyEIN: "",
    companyCity: "",
    companyState: "",
    companyZip: "",
    companyAddress: "",
    companyEmail: "",
    companyPhone: "",
    companyStateOfRegistration: "",
    numberOfOwners: 1,
  });

  const [owners, setOwners] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  // Scroll to top on form load
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component is mounted
  }, []);

  const handleCompanyChange = (e) => {
    setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
  };

  const handleOwnerChange = (index, e) => {
    const updatedOwners = [...owners];
    updatedOwners[index] = { ...updatedOwners[index], [e.target.name]: e.target.value };
    setOwners(updatedOwners);
  };

  const handleFileUpload = (index, e) => {
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedOwners = [...owners];
        updatedOwners[index] = { ...updatedOwners[index], idFile: reader.result };
        setOwners(updatedOwners);
      };
      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file type");
    }
  };

  const validateForm = () => {
    let errors = {};
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const today = new Date();

    // Validation for company details
    if (!companyDetails.companyName) errors.companyName = "Company name is required.";
    // EIN is now optional, so no validation for it
    if (!companyDetails.companyCity) errors.companyCity = "Company city is required.";
    if (!companyDetails.companyState) errors.companyState = "Company state is required.";
    if (!companyDetails.companyZip) {
      errors.companyZip = "Company ZIP code is required.";
    } else if (!/^\d{5}$/.test(companyDetails.companyZip)) {
      errors.companyZip = "ZIP code must be exactly 5 digits.";
    }
    if (!companyDetails.companyAddress) errors.companyAddress = "Company address is required.";
    if (!companyDetails.companyStateOfRegistration) errors.companyStateOfRegistration = "Company state of registration is required.";
    if (!companyDetails.companyEmail) {
      errors.companyEmail = "Company email is required.";
    } else if (!emailPattern.test(companyDetails.companyEmail)) {
      errors.companyEmail = "Company email is invalid.";
    }
    if (!companyDetails.companyPhone) errors.companyPhone = "Company phone number is required.";

    // Validation for owners
    owners.forEach((owner, index) => {
      if (!owner.firstName) errors[`firstName${index}`] = `First name for Owner ${index + 1} is required.`;
      if (!owner.lastName) errors[`lastName${index}`] = `Last name for Owner ${index + 1} is required.`;
      if (!owner.dob) {
        errors[`dob${index}`] = `Date of Birth for Owner ${index + 1} is required.`;
      } else if (new Date(owner.dob) > today) {
        errors[`dob${index}`] = `Date of Birth for Owner ${index + 1} cannot be in the future.`;
      }
      if (!owner.currentAddress) errors[`currentAddress${index}`] = `Current address for Owner ${index + 1} is required.`;
      if (!owner.city) errors[`city${index}`] = `City for Owner ${index + 1} is required.`;
      if (!owner.state) errors[`state${index}`] = `State for Owner ${index + 1} is required.`;
      if (!owner.zip) {
        errors[`zip${index}`] = `ZIP code for Owner ${index + 1} is required.`;
      } else if (!/^\d{5}$/.test(owner.zip)) {
        errors[`zip${index}`] = `ZIP code must be exactly 5 digits.`;
      }
      if (!owner.idFile) errors[`idFile${index}`] = `ID File for Owner ${index + 1} is required.`;
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    localStorage.setItem('companyDetails', JSON.stringify(companyDetails));
    localStorage.setItem('owners', JSON.stringify(owners));

    navigate("/payment");
  };

  const renderOwnerForms = () => {
    const ownerForms = [];
    for (let i = 0; i < companyDetails.numberOfOwners; i++) {
      ownerForms.push(
        <div key={i}>
          <h3>Owner {i + 1}</h3>
          <div>
            <label>First Name</label>
            <input 
              type="text" 
              name="firstName" 
              onChange={(e) => handleOwnerChange(i, e)} 
              className={formErrors[`firstName${i}`] ? 'error-border' : ''}
            />
            {formErrors[`firstName${i}`] && <p className="error">{formErrors[`firstName${i}`]}</p>}
          </div>
          <div>
            <label>Middle Name</label>
            <input type="text" name="middleName" onChange={(e) => handleOwnerChange(i, e)} />
          </div>
          <div>
            <label>Last Name</label>
            <input 
              type="text" 
              name="lastName" 
              onChange={(e) => handleOwnerChange(i, e)} 
              className={formErrors[`lastName${i}`] ? 'error-border' : ''}
            />
            {formErrors[`lastName${i}`] && <p className="error">{formErrors[`lastName${i}`]}</p>}
          </div>
          <div>
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              max={new Date().toISOString().split("T")[0]} // Set max date to today
              onChange={(e) => handleOwnerChange(i, e)}
              className={formErrors[`dob${i}`] ? 'error-border' : ''}
            />
            {formErrors[`dob${i}`] && <p className="error">{formErrors[`dob${i}`]}</p>}
          </div>
          <div>
            <label>Current Address</label>
            <input 
              type="text" 
              name="currentAddress" 
              onChange={(e) => handleOwnerChange(i, e)} 
              className={formErrors[`currentAddress${i}`] ? 'error-border' : ''}
            />
            {formErrors[`currentAddress${i}`] && <p className="error">{formErrors[`currentAddress${i}`]}</p>}
          </div>
          <div>
            <label>City</label>
            <input 
              type="text" 
              name="city" 
              onChange={(e) => handleOwnerChange(i, e)} 
              className={formErrors[`city${i}`] ? 'error-border' : ''}
            />
            {formErrors[`city${i}`] && <p className="error">{formErrors[`city${i}`]}</p>}
          </div>
          <div>
            <label>State</label>
            <select 
              name="state" 
              onChange={(e) => handleOwnerChange(i, e)} 
              className={formErrors[`state${i}`] ? 'error-border' : ''}
            >
              <option value="">Select State</option>
              {usStates.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {formErrors[`state${i}`] && <p className="error">{formErrors[`state${i}`]}</p>}
          </div>
          <div>
            <label>ZIP Code</label>
            <input 
              type="text" 
              name="zip" 
              onChange={(e) => handleOwnerChange(i, e)} 
              className={formErrors[`zip${i}`] ? 'error-border' : ''}
            />
            {formErrors[`zip${i}`] && <p className="error">{formErrors[`zip${i}`]}</p>}
          </div>
          <div>
            <label>Upload Identification (Driver License/Passport)</label>
            <input type="file" name="idFile" onChange={(e) => handleFileUpload(i, e)} />
            {formErrors[`idFile${i}`] && <p className="error">{formErrors[`idFile${i}`]}</p>}
          </div>
        </div>
      );
    }
    return ownerForms;
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Company Information</h2>
      <div>
        <label>What is your company name?</label>
        <input 
          type="text" 
          name="companyName" 
          value={companyDetails.companyName} 
          onChange={handleCompanyChange} 
          className={formErrors.companyName ? 'error-border' : ''}
        />
        {formErrors.companyName && <p className="error">{formErrors.companyName}</p>}
      </div>
      <div>
        <label>What is your company's EIN?</label>
        <input 
          type="text" 
          name="companyEIN" 
          value={companyDetails.companyEIN} 
          onChange={handleCompanyChange} 
          className={formErrors.companyEIN ? 'error-border' : ''}
        />
        {/* EIN is now optional, so no error message */}
      </div>
      <div>
        <label>What is your company's address?</label>
        <input 
          type="text" 
          name="companyAddress" 
          value={companyDetails.companyAddress} 
          onChange={handleCompanyChange} 
          className={formErrors.companyAddress ? 'error-border' : ''}
        />
        {formErrors.companyAddress && <p className="error">{formErrors.companyAddress}</p>}
      </div>
      <div>
        <label>City</label>
        <input 
          type="text" 
          name="companyCity" 
          value={companyDetails.companyCity} 
          onChange={handleCompanyChange} 
          className={formErrors.companyCity ? 'error-border' : ''}
        />
        {formErrors.companyCity && <p className="error">{formErrors.companyCity}</p>}
      </div>
      <div>
        <label>State</label>
        <select 
          name="companyState" 
          value={companyDetails.companyState} 
          onChange={handleCompanyChange} 
          className={formErrors.companyState ? 'error-border' : ''}
        >
          <option value="">Select State</option>
          {usStates.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
        {formErrors.companyState && <p className="error">{formErrors.companyState}</p>}
      </div>
      <div>
        <label>ZIP Code</label>
        <input 
          type="text" 
          name="companyZip" 
          value={companyDetails.companyZip} 
          onChange={handleCompanyChange} 
          className={formErrors.companyZip ? 'error-border' : ''}
        />
        {formErrors.companyZip && <p className="error">{formErrors.companyZip}</p>}
      </div>
      <div>
        <label>What is your company's email?</label>
        <input 
          type="email" 
          name="companyEmail" 
          value={companyDetails.companyEmail} 
          onChange={handleCompanyChange} 
          className={formErrors.companyEmail ? 'error-border' : ''}
        />
        {formErrors.companyEmail && <p className="error">{formErrors.companyEmail}</p>}
      </div>
      <div>
        <label>What is your company's phone number?</label>
        <input 
          type="tel" 
          name="companyPhone" 
          value={companyDetails.companyPhone} 
          onChange={handleCompanyChange} 
          className={formErrors.companyPhone ? 'error-border' : ''}
        />
        {formErrors.companyPhone && <p className="error">{formErrors.companyPhone}</p>}
      </div>
      <div>
        <label>What is your company's state of registration?</label>
        <select 
          name="companyStateOfRegistration" 
          value={companyDetails.companyStateOfRegistration} 
          onChange={handleCompanyChange} 
          className={formErrors.companyStateOfRegistration ? 'error-border' : ''}
        >
          <option value="">Select State</option>
          {usStates.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
        {formErrors.companyStateOfRegistration && <p className="error">{formErrors.companyStateOfRegistration}</p>}
      </div>
      <div>
        <label>How many owners does this business have?</label>
        <input 
          type="number" 
          name="numberOfOwners" 
          value={companyDetails.numberOfOwners} 
          onChange={handleCompanyChange} 
        />
      </div>

      <h2>Owner Information</h2>
      {renderOwnerForms()}

      <button type="submit">Submit</button>
    </form>
  );
};

export default BOIRForm;


