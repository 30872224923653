
import React, { useState } from 'react';
// import './HomePage.css';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const dueDate = 'December 31, 2024'; // Adjust the due date accordingly
  const punishment = 'Civil and criminal penalties, including fines up to $500 per day of violation and potential criminal penalties up to $10,000 or imprisonment for up to 2 years.';
  
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null); // To track the active FAQ

  const handleFileNowClick = () => {
    navigate('/boir-form');
  };

  // Toggle FAQ function
  const handleFaqClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div style={styles.container}>
      {/* English Version */}
      <header style={styles.header}>
        <h1 style={styles.title}>Do You or Someone You Know Own a Business in USA?</h1>
        <p style={styles.subtitle}>If so, it's crucial to be aware of an important update regarding U.S. business regulations. All corporations and LLCs are required to file their Beneficial Ownership Information Report (BOIR) by <span style={styles.dueDate}>{dueDate}</span>.</p>
      </header>
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Why is this important?</h2>
        <p style={styles.text}>
          Failure to file on time can lead to serious consequences, including:
        </p>
        <ul style={styles.list}>
          <li><span>Civil</span> and criminal penalties, with fines up to <strong style={styles.fine}>$500 per day</strong> of violation.</li>
          <li>Criminal penalties up to $10,000 or imprisonment for up to 2 years.</li>
        </ul>
        <p style={styles.text}>To avoid legal complications, make sure you file your BOIR on time.</p>
        <p style={styles.text}><strong style={styles.boldText}>Need Help?</strong> Please contact the <strong>BOIR Filing Team</strong> at <strong>206-785-5269</strong> or via email at <strong>boir@mabrookfiles.com</strong>.</p>
        <p style={styles.boldText}>Please pass this message to any friends or family members who own a business. We can help them stay compliant and avoid penalties.</p>
      </section>
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Watch Step-by-Step form filing Video</h2>
        <iframe
          width="100%"
          height="450"
          src="https://www.youtube.com/embed/GrAhcLlUzfE "
          title="Video Explanation"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </section>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleFileNowClick}>
          File Now
        </button>
      </div>

      {/* Amharic Version */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>እርስዎ ወይም የታወቁ ሰው በአሜሪካ ንግድ አለብዎት?</h2>
        <p style={styles.text}>እንደሆነ እንዲህን አስተውሉ፣ ሁሉም ኮርፖሬሽኖችና LLC's በቅጣት የሚያስፈሪያ ማስታወቂያ እስከ <span style={styles.dueDate}>{dueDate}</span> እስከዚህ ጊዜ ማስገባት አለባቸው።</p>
        <h3 style={styles.sectionTitle}>ለምን በሚታወቀው ነው?</h3>
        <p style={styles.text}>ያልተከበረ የጊዜ ግዴታ ከባሻችው እንደሚታወቅ ክህሎት ሊያገኙት እንደሚችል እንዳስተውሉ፦</p>
        <ul style={styles.list}>
          <li><span>Civil</span> እና የጥፋት ቅጣቶች፣ በየቀኑ <strong style={styles.fine}>$500</strong> እስከ ማስታገር ድረስ።</li>
          <li>እስከ $10,000 እና ለ2 ዓመት ድረስ የእስርቤት ጥፋት።</li>
        </ul>
        <p style={styles.text}>ሕጋዊ ችግሮችን ለመከላከል፣ የተሻለውን BOIR በጊዜው ማስገባትን ያረጉ።</p>
        <p style={styles.text}><strong style={styles.boldText}>ይወዳሉ እርዳታ?</strong> እባክዎ በስልክ ቁጥር <strong>206-785-5269</strong> ወይም በኢሜይል <strong>boir@mabrookfiles.com</strong> ያነጋግሩን።</p>
        <p style={styles.boldText}>እባክዎትን መልእክቱን ለንግድ ያላቸው ወዳጆችና ቤተሰብ ያስተላልፉ። እኛ እነርሱን ማስገባትና ከቅጣት ማዳን እንርዳለን።</p>
      </section>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleFileNowClick}>
          File Now
        </button>
      </div>

      {/* Oromo Version */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Ati yookin namni ati beektu daldala qaba USA keessatti?</h2>
        <p style={styles.text}>Yoo akkas ta’e, akkaataa sirna seeraa daldala Ameerikaa keessa jiru beekuun barbaachisaa dha. Dhaabbilee fi LLC hundi Beneficial Ownership Information Report (BOIR) hanga <span style={styles.dueDate}>{dueDate}</span> galchuu qabu.</p>
        <h3 style={styles.sectionTitle}>Maaliif barbaachisaa?</h3>
        <p style={styles.text}>Yeroon isaa yoo darbu adabbii hammaataa qabaachuu danda’a:</p>
        <ul style={styles.list}>
          <li><span>Civil</span> fi yakkaa, adabbii <strong style={styles.fine}>$500 guyyaa kamiyyuu</strong> badii hojjetameef.</li>
          <li>Adabbii yakkaa $10,000 ykn hidhaa waggaa lamaa hanga beekkamu.</li>
        </ul>
        <p style={styles.text}>Rakkoo seeraa keessa hinseenin BOIR keessan yeroon galmeessaa.</p>
        <p style={styles.text}><strong style={styles.boldText}>Tajaajila barbaaddduu?</strong>  Bilbilaa BOIR Filing Team lakkoofsa <strong>206-785-5269</strong> ykn Email <strong>boir@mabrookfiles.com</strong> irratti.</p>
        <p style={styles.boldText}>Mee ergaa kana firaaf hiriyoota daldala qaban bira geenyaa. Nu waliin akka seera hojjennu nuuf ta’aa.</p>
      </section>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleFileNowClick}>
          File Now
        </button>
      </div>

      {/* Somali Version */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Adiga ama qof aad taqaan ganacsi ma leedahay USA?</h2>
        <p style={styles.text}>Haddii ay sidaas tahay, waa muhiim inaad ogaato war muhiim ah oo ku saabsan xeerarka ganacsiga Mareykanka. Dhammaan shirkadaha iyo LLC-yada waa inay soo gudbiyaan Beneficial Ownership Information Report (BOIR) kahor <span style={styles.dueDate}>{dueDate}</span>.</p>
        <h3 style={styles.sectionTitle}>Maxay muhiim u tahay?</h3>
        <p style={styles.text}>Inaan waqtigeeda la gudbin waxay horseedi kartaa dhibaatooyin culus, oo ay ku jiraan:</p>
        <ul style={styles.list}>
          <li><span>Civil</span> ciqaab bulsho iyo mid ciqaabeed, oo ganaaxyo gaaraya ilaa <strong style={styles.fine}>$500 maalintii</strong>.</li>
          <li>Ciqaabo dembiyeed oo gaaraya $10,000 ama xabsi gaaraya 2 sano.</li>
        </ul>
        <p style={styles.text}>Si looga fogaado dhibaatooyin sharciyeed, xaqiiji inaad BOIR-kaaga waqtigeeda gudbiso.</p>
        <p style={styles.text}><strong style={styles.boldText}>Ma u baahan tahay caawimo?</strong> Fadlan la xiriir <strong>BOIR Filing Team</strong> lambarka <strong>206-785-5269</strong> ama email <strong>boir@mabrookfiles.com</strong>.</p>
        <p style={styles.boldText}>Fadlan fariintan u gudbi saaxiibadaada iyo qoyskaaga oo ganacsi leh. Waxaan ka caawin karnaa inay la jaan qaadaan sharciga oo ay ka fogaadaan ganaaxyo.</p>
      </section>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleFileNowClick}>
          File Now
        </button>
      </div>

      {/* Arabic Version */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>هل تمتلك أنت أو شخص تعرفه شركة في الولايات المتحدة الأمريكية؟</h2>
        <p style={styles.text}>إذا كان الأمر كذلك، من المهم أن تكون على دراية بتحديث مهم بشأن لوائح الأعمال الأمريكية. يجب على جميع الشركات والمؤسسات ذات المسؤولية المحدودة (LLC) تقديم تقرير المعلومات المتعلقة بالملكية المستفيدة (BOIR) بحلول <span style={styles.dueDate}>{dueDate}</span>.</p>
        <h3 style={styles.sectionTitle}>لماذا هذا مهم؟</h3>
        <p style={styles.text}>عدم التقديم في الوقت المناسب قد يؤدي إلى عواقب وخيمة، بما في ذلك:</p>
        <ul style={styles.list}>
          <li><span>Civil</span> وجنائية، مع غرامات تصل إلى <strong style={styles.fine}>500 دولار يوميًا</strong> عن كل انتهاك.</li>
          <li>عقوبات جنائية تصل إلى 10,000 دولار أو السجن لمدة تصل إلى سنتين.</li>
        </ul>
        <p style={styles.text}>لتجنب التعقيدات القانونية، تأكد من تقديم تقرير BOIR الخاص بك في الوقت المحدد.</p>
        <p style={styles.text}><strong style={styles.boldText}>هل تحتاج إلى مساعدة؟</strong>  يرجى الاتصال بفريق تقديم BOIR على الرقم <strong>5269-785-206</strong> أو عبر البريد الإلكتروني <strong>boir@mabrookfiles.com</strong>.</p>
        <p style={styles.boldText}>يرجى تمرير هذه الرسالة إلى أي أصدقاء أو أفراد من العائلة يمتلكون شركة. يمكننا مساعدتهم على الامتثال وتجنب الغرامات.</p>
      </section>

      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleFileNowClick}>
          قدم الآن
        </button>
      </div>

      {/* FAQ Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Frequently Asked Questions</h2>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion} onClick={() => handleFaqClick(0)}>What is a BOI Report?</h3>
          {activeIndex === 0 && <p style={styles.faqAnswer}>A BOI Report is the Beneficial Ownership Information Report that corporations and LLCs are required to file under new U.S. business regulations.</p>}
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion} onClick={() => handleFaqClick(1)}>What is the new BOI Reporting rule?</h3>
          {activeIndex === 1 && <p style={styles.faqAnswer}>The new rule mandates that all businesses, such as corporations and LLCs, report their beneficial ownership information to the relevant authority.</p>}
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion} onClick={() => handleFaqClick(2)}>Who needs to file BOI Reports?</h3>
          {activeIndex === 2 && <p style={styles.faqAnswer}>All corporations, LLCs, and similar business entities operating in the U.S. must file a BOI Report.</p>}
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion} onClick={() => handleFaqClick(3)}>What is the BOI report for LLC?</h3>
          {activeIndex === 3 && <p style={styles.faqAnswer}>LLCs are required to file the BOI Report to disclose their beneficial ownership information, including individuals with significant control or ownership stakes.</p>}
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion} onClick={() => handleFaqClick(4)}>Where to file BOI Report?</h3>
          {activeIndex === 4 && <p style={styles.faqAnswer}>The BOI Report can be filed online through the designated government portal or platform designed for business filings.</p>}
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion} onClick={() => handleFaqClick(5)}>What are the BOI Reporting Exemptions?</h3>
          {activeIndex === 5 && <p style={styles.faqAnswer}>Certain entities, such as publicly traded companies and regulated entities, may be exempt from BOI reporting requirements.</p>}
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion} onClick={() => handleFaqClick(6)}>What is BOI Reporting on hold?</h3>
          {activeIndex === 6 && <p style={styles.faqAnswer}>If your BOI report filing is on hold, it could mean that further clarification or additional documents are required before final submission.</p>}
        </div>
      </section>

    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: '0 auto',
    padding: '20px',
    maxWidth: '800px',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '20px',
    color: '#555',
  },
  section: {
    backgroundColor: '#f8f8f8',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '40px',
  },
  sectionTitle: {
    fontSize: '28px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '18px',
    lineHeight: '1.6',
  },
  highlight: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#007BFF',
  },
  dueDate: {
    color: 'red',
    fontWeight: 'bold',
  },
  fine: {
    color: '#FF4500', // Darker red for fine amounts
  },
  boldText: {
    fontWeight: 'bold',
  },
  list: {
    fontSize: '18px',
    lineHeight: '1.8',
    listStyleType: 'disc',
    paddingLeft: '20px',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '30px',
  },
  button: {
    padding: '15px 30px',
    fontSize: '18px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  faqItem: {
    marginBottom: '20px',
  },
  faqQuestion: {
    cursor: 'pointer',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#007BFF',
  },
  faqAnswer: {
    fontSize: '18px',
    color: '#555',
    marginTop: '10px',
  },
};

export default HomePage;

