import React from 'react';
import { useLocation } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const companyDetails = location.state?.companyDetails; // Get passed data from location state
  const paymentId = location.state?.paymentId; // Get the Payment ID
  const cardStatus = location.state?.cardStatus; // Get the Card Details Status

  if (!companyDetails) {
    return <p>Error: No submission details found.</p>;
  }

  return (
    <div>
      <h1>Submission Confirmation</h1>
      <p>Thank you for your submission! Here are your company details:</p>
      <ul>
        <li><strong>Company Name:</strong> {companyDetails.companyName}</li>
        <li><strong>Company EIN:</strong> {companyDetails.companyEIN}</li>
        <li><strong>Company Address:</strong> {companyDetails.companyAddress}</li>
        <li><strong>City:</strong> {companyDetails.companyCity}</li>
        <li><strong>State:</strong> {companyDetails.companyState}</li>
        <li><strong>ZIP Code:</strong> {companyDetails.companyZip}</li>
        <li><strong>Email:</strong> {companyDetails.companyEmail}</li>
        <li><strong>Phone:</strong> {companyDetails.companyPhone}</li>
        <li><strong>State of Registration:</strong> {companyDetails.companyStateOfRegistration}</li>
      </ul>

      <p>Your payment has been processed successfully.</p>
      
      {/* Display Payment ID and Card Status */}
      <p><strong>Payment ID:</strong> {paymentId}</p>
      <p><strong>Card Details Status:</strong> {cardStatus}</p>

      <p>
        <strong>Your company BOIR application has been received and it will be submitted to FINCERN within 72 hours. 
        Please keep this as your receipt. If you have any questions, you can contact us at the 
        <strong> BOIR Mabrook Files Team</strong> at <a href="tel:2067855269">206-785-5269</a> or email us at 
        <a href="mailto:boir@mabrookfiles.com">boir@mabrookfiles.com</a>.
        </strong>
      </p>
    </div>
  );
};

export default ConfirmationPage;
