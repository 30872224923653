import React, { useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SquarePayment = () => {
  const [paymentStatus, setPaymentStatus] = useState('');
  const navigate = useNavigate();
  const amountToCharge = '165.00';

  const submitFormAndSendEmail = async (token, lastFour) => {
    const companyDetails = JSON.parse(localStorage.getItem('companyDetails'));
    const owners = JSON.parse(localStorage.getItem('owners'));

    const formData = new FormData();

    // Append company details
    formData.append('companyName', companyDetails.companyName);
    formData.append('companyEin', companyDetails.companyEIN);
    formData.append('companyAddress', companyDetails.companyAddress);
    formData.append('companyCity', companyDetails.companyCity);
    formData.append('companyState', companyDetails.companyState);
    formData.append('companyZip', companyDetails.companyZip);
    formData.append('companyEmail', companyDetails.companyEmail);
    formData.append('companyPhone', companyDetails.companyPhone);
    formData.append('companyStateOfRegistration', companyDetails.companyStateOfRegistration);

    // Append token and last four digits of card
    formData.append('token', token);
    formData.append('lastFourDigits', lastFour);

    // Append owner details
    owners.forEach((owner, index) => {
      formData.append(`owners[${index}][firstName]`, owner.firstName);
      formData.append(`owners[${index}][middleName]`, owner.middleName);
      formData.append(`owners[${index}][lastName]`, owner.lastName);
      formData.append(`owners[${index}][dob]`, owner.dob);
      formData.append(`owners[${index}][currentAddress]`, owner.currentAddress);
      formData.append(`owners[${index}][city]`, owner.city);
      formData.append(`owners[${index}][state]`, owner.state);
      formData.append(`owners[${index}][zip]`, owner.zip);
      formData.append(`owners[${index}][idFile]`, owner.idFile);
    });

    try {
      const response = await axios.post("https://mabrookfiles.com/mybackend/api/sendForm.php", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      // Extract the payment ID and card details status from the response
      const paymentId = response.data.paymentId; // Extract the payment ID
      const cardStatus = response.data.cardDetailsStatus; // Extract the card details status

      if (response.data.status === 'success') {
        setPaymentStatus('Form submitted and payment processed successfully!');

        // Redirect to the confirmation page with the company details, payment ID, and card status
        navigate('/confirmation', { state: { companyDetails, paymentId, cardStatus } });
      } else {
        setPaymentStatus(`Failed to submit form or process payment: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error submitting form and sending email:", error);
      setPaymentStatus('Error submitting form or sending email.');
    }
  };

  return (
    <div>
      <h1>BOIR Filing Fee</h1>
      <p><strong>Amount to be charged: ${amountToCharge} USD</strong></p>
      <PaymentForm
        applicationId="sq0idp--ln8k2zw_dSGxyMEpIxUqQ"
        locationId="0FMHH1EATWSSZ"
        cardTokenizeResponseReceived={(token, buyer) => {
          if (token) {
            const lastFourDigits = token.details.card.last4;
            setPaymentStatus('Payment successful! Submitting form...');
            submitFormAndSendEmail(token.token, lastFourDigits); // Submit form with token and last four digits
          } else {
            setPaymentStatus('Payment failed. Please try again.');
          }
        }}
        createVerificationDetails={() => {
          const companyDetails = JSON.parse(localStorage.getItem('companyDetails'));
          return {
            amount: amountToCharge,
            currencyCode: 'USD',
            intent: 'CHARGE',
            billingContact: {
              familyName: 'Doe',
              givenName: 'John',
              email: companyDetails.companyEmail,
              addressLines: [companyDetails.companyAddress],
              city: companyDetails.companyCity,
              state: companyDetails.companyState,
              postalCode: companyDetails.companyZip,
              countryCode: 'US',
              phone: companyDetails.companyPhone,
            },
          };
        }}
      >
        <CreditCard />
      </PaymentForm>
      <p>{paymentStatus}</p>
    </div>
  );
};

export default SquarePayment;
