import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import BottomNav from './components/BottomNav';
import HomePage from './components/HomePage';
import BOIRForm from './components/BOIRForm'; 
import ContactUs from './components/ContactUs'; 
import SquarePayment from "./components/SquarePayment";
import ConfirmationPage from "./components/ConfirmationPage";
import BOIRPage from "./components/BOIRPage";
import './App.css';

function App() {
  return (
    <Router>
      <NavBar />
      {/*<EmailVerification />*/}
      <Routes>
        <Route path="/" element={<HomePage />} />
       
          <Route path="/boir-form" element={<BOIRForm />} />
           <Route path="/contact-us" element={<ContactUs />} />
           <Route path="/payment" element={<SquarePayment />} />
            <Route path="/confirmation" element={<ConfirmationPage />} />
            <Route path="/boir-page" element={<BOIRPage />} />
         
        {/* Add other routes here */}
      </Routes>
      <BottomNav />
    </Router>
  );
}

export default App;
