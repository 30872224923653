import React from 'react';

const BOIRPage = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>BOI Reporting</h1>
      <p>
        <strong>Business started after Jan 1, 2024?</strong>
        <br />
        You have 90 days to file from the date of formation.
      </p>
      <p>
        <strong>Business started prior to 2024?</strong>
        <br />
        Report ownership information before Dec. 31st, 2024.
      </p>

      <h2>What is BOI Reporting?</h2>
      <p>
        BOI Reporting, short for Beneficial Ownership Information Reporting, is a critical requirement set by the Financial Crimes Enforcement Network (FinCEN), a branch of the U.S. Department of the Treasury. This regulation is designed to enhance transparency in the financial system by identifying the beneficial owners of legal entities.
      </p>

      <h2>Importance of BOI Reporting</h2>
      <p>
        BOI Reporting plays a pivotal role in safeguarding the integrity of the financial system. By disclosing the individuals behind legal entities, it helps prevent money laundering, fraud, and other illicit financial activities. Complying with BOI Reporting is not just a legal obligation; it’s a vital step toward a more transparent and secure business environment.
      </p>

      <h2>Who Needs to File BOI Reports?</h2>
      <p>
        BOI Reporting is a requirement for certain legal entities in the United States. This includes corporations, limited liability companies (LLCs), and other similar entities. If your business falls into one of these categories, you are obligated to file BOI Reports. However, the specifics can vary, so it’s essential to understand your unique compliance obligations.
      </p>

      <h2>How BOI Filings Simplifies BOI Reporting</h2>
      <p>
        Navigating the complexities of BOI Reporting can be daunting, but that’s where U.S Business Compliance Commission comes in. We’ve developed a streamlined process that transforms what can be a challenging task into a simple and efficient one.
      </p>
      <p>
        Our approach is designed with your convenience in mind. We provide a one-page submission form that eliminates unnecessary complexity, making it easy for you to fulfill your compliance requirements.
      </p>
      <p>
        We understand that time is of the essence, which is why we offer expedited services with 48-hour delivery and same-day options. With U.S Business Compliance Commission, you can meet your BOI Reporting obligations without the stress and hassle.
      </p>
    </div>
  );
};

export default BOIRPage;
